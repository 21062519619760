import config from '@configFile';
import {
  clearDashboardParams,
  setLocalStorageParameters
} from './localStorage';

export default {
  MOST_POPULAR_COUPON_DEALS: {
    id: 'mostPopularCouponDeals',
    tag: config.AFFILIATE_TAGS.MOST_POPULAR_COUPON,
    params: {
      limit: 8,
      filters: ['hasCoupon'],
      sort: 'best-selling'
    },
    seeAllText: 'See all of the most popular coupon deals',
    trackUsepreFix: 'most-popular-coupon-deal',
    title: 'Most Popular Amazon Coupon Deals',
    onSeeAllClick: () => {
      clearDashboardParams();
      setLocalStorageParameters({
        sort: 'newest',
        filter_only_favorites: false,
        filter_only_coupons: true,
        filter_with_reviews: false,
        filter_category_filters: []
      });
    }
  },
  LARGEST_DISCOUNT_COUPON_DEALS: {
    id: 'largestDiscountCouponDeals',
    tag: config.AFFILIATE_TAGS.MOST_POPULAR_COUPON,
    params: {
      limit: 12,
      filters: ['hasCoupon'],
      sort: 'largest-discount'
    },
    seeAllText: 'See all of the best coupon deals',
    trackUsepreFix: 'largest-discount-coupon-deals',
    title: "Amazon's Largest Discount Coupon Deals",
    onSeeAllClick: () => {
      clearDashboardParams();
      setLocalStorageParameters({
        sort: 'highest-discount',
        filter_only_favorites: false,
        filter_only_coupons: true,
        filter_with_reviews: false,
        filter_category_filters: []
      });
    }
  },
  LATEST_COUPON_DEALS: {
    id: 'latestDiscountCouponDeals',
    tag: config.AFFILIATE_TAGS.MOST_POPULAR_COUPON,
    params: {
      limit: 12,
      filters: ['hasCoupon'],
      sort: 'newest'
    },
    seeAllText: 'See all of the best coupon deals',
    trackUsepreFix: 'latest-discount-coupon-deals',
    title: 'Latest Amazon Coupon Deals',
    onSeeAllClick: () => {
      clearDashboardParams();
      setLocalStorageParameters({
        sort: 'newest',
        filter_only_favorites: false,
        filter_only_coupons: true,
        filter_with_reviews: false,
        filter_category_filters: []
      });
    }
  }
};
