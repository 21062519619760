import React from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import App from './app';
import { dealsAction, listAction } from './actions';
import dealRails from './utils/dealRails';

import {
  asyncAccount,
  asyncAPI,
  NotFound,
  asyncSubscribeSaveNew,
  asyncVariations,
  asyncCoupons,
  asyncDealsIdeas,
  asyncMakeLists,
  asyncList,
  asyncDealLists,
  asyncDaily,
  asyncPrivacyPolicy,
  asyncPosts,
  asyncDeals,
  asyncBlog,
  asyncGiftCards,
  asyncSignin,
  asyncSocial,
  asyncDealsEventDay,
  asyncSales,
  asyncSignup,
  asyncVerifyAccount,
  asyncUserSubmitDeal,
  asyncReviewSubmissions,
  asyncPasswordReset,
  asyncWeekly,
  asyncResetPassword,
  asyncVariationsSearched,
  asyncEmail,
  asyncPages,
  asyncUsers,
  asyncFavorites,
  asyncTermsOfService,
  asyncSubscribeSaveFAQ,
  asyncSearch
} from './pages';
import { DEALS_LOADING_DEAL_SUMMARY } from './types';

const routerKeys = ['path', 'exact', 'component', 'loadData'];
const siteMapKeys = ['url', 'changefreq', 'priority', 'combo', 'comboQuery'];

const masterRoutes = [
  {
    path: ['/', '/deal/:ASIN'],
    exact: true,
    component: asyncDeals,
    loadData: ({ params: { ASIN } }: { params: { ASIN?: string } }) => {
      if (ASIN) {
        return [
          dealsAction.getPageDealSlugs(),
          dealsAction.getDealSummary(ASIN),
          {
            type: DEALS_LOADING_DEAL_SUMMARY,
            dealSummaryASIN: ASIN
          }
        ];
      }
      return [];
    },
    url: '/deal/',
    changefreq: 'daily',
    priority: 0.5
  },
  {
    path: ['/users', '/users/:userId'],
    exact: true,
    component: asyncUsers
  },
  {
    path: '/verify-account/:verifyEmailId',
    component: asyncVerifyAccount
  },
  {
    path: '/submit-deal',
    component: asyncUserSubmitDeal
  },
  {
    path: '/best-amazon-subscribe-save-deals',
    url: '/best-amazon-subscribe-save-deals',
    exact: true,
    changefreq: 'daily',
    priority: 1,
    component: asyncSubscribeSaveNew,
    loadData: () => {
      return [
        dealsAction.getPageDealSlugs(),
        dealsAction.getPageDeal('best-subscribe-save-deals-amazon')
      ];
    }
  },
  {
    path: '/amazon-subscribe-save-tips',
    url: '/amazon-subscribe-save-tips',
    exact: true,
    changefreq: 'daily',
    priority: 1,
    component: asyncSubscribeSaveFAQ
    // loadData: () => {
    //   return [
    //     dealsAction.getPageDealSlugs(),
    //     // dealsAction.getPageDeal('best-subscribe-save-deals-amazon')
    //   ];
    // }
  },
  {
    path: '/subscribe-save-new',
    exact: true,
    component: asyncSubscribeSaveNew,
    loadData: () => {
      return [
        dealsAction.getPageDealSlugs(),
        dealsAction.getPageDeal('best-subscribe-save-deals-amazon')
      ];
    }
  },
  {
    path: ['/p/:slug'],
    exact: true,
    component: asyncPages,
    loadData: ({ params: { slug } }: { params: { slug?: string } }) => {
      const existing = [dealsAction.getPageDealSlugs()];
      if (slug && slug !== 'undefined' && slug !== 'null') {
        existing.push(dealsAction.getPageDeal(slug));
      }
      return existing;
    },
    url: '/p/',
    changefreq: 'daily',
    priority: 1,
    comboQuery: `SELECT slug FROM PageDeals WHERE isActive ='1' AND numLiveDeals > 1 ORDER BY postDate DESC;`
  },
  {
    path: '/variations',
    url: '/variations',
    exact: true,
    component: asyncVariations,
    changefreq: 'daily',
    priority: 0.7,
    loadData: () => {
      const existing: Function[] = [dealsAction.getPageDealSlugs()];
      return [...existing];
    }
  },
  {
    path: '/daily',
    url: '/daily',
    exact: true,
    component: asyncDaily,
    changefreq: 'daily',
    priority: 1,
    loadData: () => {
      const existing: Function[] = [dealsAction.getPageDealSlugs()];
      return [...existing];
    }
  },
  {
    path: '/favorites',
    exact: true,
    component: asyncFavorites
  },
  {
    path: '/dealsIdeas',
    exact: true,
    component: asyncDealsIdeas
  },
  {
    path: '/search',
    url: '/search',
    changeFreq: 'weekly',
    priorty: 0.5,
    exact: true,
    component: asyncSearch
  },
  {
    path: '/social',
    url: '/social',
    changeFreq: 'daily',
    priorty: 1,
    exact: true,
    component: asyncSocial
  },
  {
    path: '/feed',
    exact: false,
    component: asyncBlog,
    loadData: () => {
      const existing: Function[] = [dealsAction.getPageDealSlugs()];
      return [...existing];
    }
  },
  {
    path: '/blog',
    url: '/blog',
    exact: true,
    component: asyncBlog,
    changefreq: 'daily',
    priority: 1,
    loadData: () => {
      const existing: Function[] = [dealsAction.getPageDealSlugs()];
      return [...existing];
    }
  },
  {
    path: ['/blog/:slug'],
    component: asyncBlog,
    // changefreq: 'daily',
    // priority: 0.7,
    // url: '/blog/',
    // comboQuery:
    // 'SELECT slug FROM deals WHERE slug IS NOT NULL ORDER BY date_first_active DESC;',
    // comboQuery:
    // 'SELECT slug FROM deals WHERE is_live = "1" AND slug IS NOT NULL AND is_active="1" ORDER BY date_first_active DESC;',
    loadData: ({ params: { slug } }: { params: { slug?: string } }) => {
      const existing: Function[] = [dealsAction.getPageDealSlugs()];
      if (slug && slug !== 'undefined' && slug !== 'null') {
        existing.push(dealsAction.getBlogSEO(slug));
      }

      return [...existing];
    }
  },
  {
    path: '/primeday',
    exact: true,
    component: asyncDealsEventDay
  },
  {
    path: '/black-friday',
    exact: true,
    component: asyncDealsEventDay
  },
  {
    path: '/cyber-monday',
    exact: true,
    component: asyncDealsEventDay
  },
  {
    path: '/api',
    exact: true,
    component: asyncAPI
  },
  {
    path: ['/amazon-coupon-deals', '/amazon-coupon-deals/:promoCode'],
    url: '/amazon-coupon-deals/',
    comboQuery:
      'SELECT promoCode FROM CouponDetails WHERE numLiveDeals > 0 AND image IS NOT NULL;',
    exact: true,
    changefreq: 'daily',
    loadData: ({
      params: { promoCode }
    }: {
      params: { promoCode?: string };
    }) => {
      const existing = [dealsAction.getPageDealSlugs()];

      if (promoCode && promoCode !== 'undefined' && promoCode !== 'null') {
        existing.push(dealsAction.getPromoCodeDeal(promoCode));
      }
      const {
        LARGEST_DISCOUNT_COUPON_DEALS,
        MOST_POPULAR_COUPON_DEALS,
        LATEST_COUPON_DEALS
      } = dealRails;
      return [
        ...existing,
        dealsAction.getDealRail(
          MOST_POPULAR_COUPON_DEALS.params,
          MOST_POPULAR_COUPON_DEALS.id
        ),
        dealsAction.getDealRail(
          LARGEST_DISCOUNT_COUPON_DEALS.params,
          LARGEST_DISCOUNT_COUPON_DEALS.id
        ),
        dealsAction.getDealRail(
          LATEST_COUPON_DEALS.params,
          LATEST_COUPON_DEALS.id
        )
      ];
    },
    component: asyncCoupons
  },
  {
    path: '/toy-deals',
    exact: true,
    component: () => <Redirect to="/p/amazons-best-deals-toys-games" />
  },
  {
    path: '/gift-card-deals',
    url: '/gift-card-deals',
    exact: true,
    component: asyncGiftCards,
    changefreq: 'daily',
    priority: 0.7
  },
  {
    path: '/weekly',
    url: '/weekly',
    exact: true,
    component: asyncWeekly,
    changefreq: 'daily',
    priority: 0.7
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: asyncPrivacyPolicy
  },
  {
    path: '/terms',
    exact: true,
    component: asyncTermsOfService
  },
  {
    path: '/signup',
    exact: true,
    component: asyncSignup
  },
  {
    path: '/signin',
    exact: true,
    component: asyncSignin
  },
  {
    path: '/login',
    exact: true,
    component: asyncSignin
  },
  {
    path: '/password-reset',
    exact: true,
    component: asyncPasswordReset
  },
  {
    path: '/variations-searched',
    exact: true,
    component: asyncVariationsSearched
  },
  {
    path: '/account',
    exact: true,
    component: asyncAccount
  },
  {
    path: '/reset-password',
    exact: true,
    component: asyncResetPassword
  },
  {
    path: '/review-submissions',
    exact: true,
    component: asyncReviewSubmissions
  },
  {
    path: '/mailing-list-recommendations',
    exact: true,
    component: asyncPosts
  },
  {
    path: '/stats',
    exact: true,
    component: asyncSales
  },
  {
    path: '/deals',
    exact: true,
    component: asyncDeals
  },
  {
    path: '/make-lists',
    exact: true,
    component: asyncMakeLists
  },
  {
    path: '/deal-lists',
    exact: true,
    component: asyncDealLists
  },
  {
    path: '/email',
    exact: true,
    component: asyncEmail
  },
  {
    path: '/list/:slug',
    url: '/list/',
    loadData: ({ params }: { params: { slug: string } }) => [
      listAction.loadListFromSlug(params.slug)
    ],
    changefreq: 'monthly',
    priority: 0.5,
    component: asyncList,
    comboQuery:
      'SELECT slug FROM lists WHERE deleted_list IS NULL AND is_live  = "1";'
  },
  {
    path: '/notFound',
    exact: true,
    component: NotFound
  },
  {
    component: asyncAPI
  }
];

export const siteMapRoutes = _.filter(
  _.map(masterRoutes, (obj) => _.pick(obj, siteMapKeys)),
  (obj2) => !_.isEmpty(obj2)
);
const routerRoutes = _.filter(
  _.map(masterRoutes, (obj) => _.pick(obj, routerKeys)),
  (obj2) => !_.isEmpty(obj2)
);

export default [
  {
    component: App,
    routes: routerRoutes
  }
];
