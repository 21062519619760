import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/system';

const SkipToContent = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <Box
      component="a"
      href="#main-content"
      sx={{
        position: 'absolute',
        top: '-40px',
        left: '0',
        background: isDarkMode ? theme.palette.background.paper : '#0969da',
        color: '#fff',
        padding: '10px',
        zIndex: '100000',
        fontSize: '14px',
        '&:focus': {
          top: '0'
        }
      }}
    >
      Skip to main content
    </Box>
  );
};

export default SkipToContent;
