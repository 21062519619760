import {
  isWithinInterval,
  subHours,
  differenceInDays,
  startOfDay,
  endOfDay,
  parseISO,
  isBefore,
  format,
  isAfter
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import config from '@configFile';

export function daysUntil(date: string | Date): number {
  const today = new Date();
  return differenceInDays(new Date(date), today);
}

export const daysRemaining = (expires: string | Date) => {
  const days = daysUntil(expires);
  if (days === 1) {
    return '1 day remaining';
  }
  return days > 0 ? `${days} days remaining` : 'ends tonight';
};

export const formatDateAdmin = (date: string | Date): string => {
  return format(new Date(date), 'EEEE LLL do, yyyy hh:mm a');
};

export const formatDateTime = (date: Date): string => {
  return format(date, 'MMM d, yyyy @ h:mm a zzz');
};

export const isDateWithinLast24Hours = (dateToCheck: Date) => {
  const now = new Date();
  const twentyFourHoursAgo = subHours(now, 24);

  const interval = {
    start: twentyFourHoursAgo,
    end: now
  };

  return isWithinInterval(dateToCheck, interval);
};

export const formattedDate = format(new Date(), 'MMM. do, yyyy');

export const formatDateUpdatedToday = () => {
  return `Updated ${formattedDate}`;
};

const getCurrentTimeInPacific = (): Date => {
  const timeZone = 'America/Los_Angeles'; // Pacific Time Zone
  const now = new Date();
  const zonedDate = utcToZonedTime(now, timeZone);
  return zonedDate;
};

const getStartOfDateInPacific = (startDate: string): string => {
  const timeZone = 'America/Los_Angeles';
  const date = parseISO(startDate); // Parse the input date string
  const zonedDate = utcToZonedTime(date, timeZone); // Convert the date to the Pacific Time Zone
  const startOfDate = startOfDay(zonedDate); // Get the start of the day in the Pacific Time Zone
  return format(startOfDate, 'yyyy-MM-dd HH:mm:ss');
};

const getEndOfDateInPacific = (endDate: string): string => {
  const timeZone = 'America/Los_Angeles';
  const date = parseISO(endDate); // Parse the input date string
  const zonedDate = utcToZonedTime(date, timeZone); // Convert the date to the Pacific Time Zone
  const endOfDate = endOfDay(zonedDate); // Get the start of the day in the Pacific Time Zone
  return format(endOfDate, 'yyyy-MM-dd HH:mm:ss');
};

const isCurrentDateInRange = (startDate: string, endDate: string): boolean => {
  const currentPacificTime = getCurrentTimeInPacific();
  const start = new Date(getStartOfDateInPacific(startDate));
  const end = new Date(getEndOfDateInPacific(endDate));

  if (isBefore(currentPacificTime, end) && isAfter(currentPacificTime, start)) {
    return true;
  }

  return false;
};

const isTodayBefore = (startDate: string): boolean => {
  const currentPacificTime = getCurrentTimeInPacific();
  const start = new Date(getStartOfDateInPacific(startDate));

  if (isBefore(currentPacificTime, start)) {
    return true;
  }

  return false;
};

export const isBeforePrimeDay = (): boolean => {
  return isTodayBefore(config.DATES.PRIME_DAY_START);
};

export const isBeforeLaborDay = (): boolean => {
  return isTodayBefore(config.DATES.LABOR_DAY_START);
};

export const isLaborDay = (): boolean => {
  return isCurrentDateInRange(
    config.DATES.LABOR_DAY_START,
    config.DATES.LABOR_DAY_END
  );
};

export const isPrimeDay = (): boolean => {
  return isCurrentDateInRange(
    config.DATES.PRIME_DAY_START,
    config.DATES.PRIME_DAY_END
  );
};

export const isBlackFridayDealsWeek = (): boolean => {
  return isCurrentDateInRange(
    config.DATES.BLACK_FRIDAY_WEEK_START,
    config.DATES.BLACK_FRIDAY_WEEK_END
  );
};

export const isBlackFriday = (): boolean => {
  return isCurrentDateInRange(
    config.DATES.BLACK_FRIDAY,
    config.DATES.BLACK_FRIDAY
  );
};

export const isBeforeBlackFriday = (): boolean => {
  return isTodayBefore(config.DATES.BLACK_FRIDAY_WEEK_START);
};

export const isCyberMonday = (): boolean => {
  return isCurrentDateInRange(
    config.DATES.CYBER_MONDAY_START,
    config.DATES.CYBER_MONDAY_END
  );
};

export const displayShippingCutoffMessage = (): boolean => {
  return isCurrentDateInRange(
    config.DATES.SHIPPING_CUTOFF_MESSAGE_START,
    config.DATES.SHIPPING_CUTOFF_MESSAGE_END
  );
};

export const displayTwelveDaysOfDeals = (): boolean => {
  return isCurrentDateInRange(
    config.DATES.TWELVE_DAYS_OF_DEALS_START,
    config.DATES.TWELVE_DAYS_OF_DEALS_END
  );
};

export const isBeforeCyberMonday = (): boolean => {
  return isTodayBefore(config.DATES.CYBER_MONDAY_START);
};

export const getDaysBetweenNowAndDate = (date: string): number => {
  const now = new Date();
  const eventDate = new Date(date);
  return differenceInDays(eventDate, now) + 1;
};
