import React from 'react';
import { Box } from '@mui/material';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';

export default function Ticker({ endDate }: { endDate: Date }) {
  return (
    <Box display="inline-flex" justifyContent="center" marginTop="8px">
      <FlipClockCountdown to={endDate.toISOString()} />
    </Box>
  );
}
