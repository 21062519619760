import React from 'react';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';

interface HideOnScrollProps {
  children: React.ReactElement;
}

function HideOnScroll(props: HideOnScrollProps) {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  const trigger = useScrollTrigger({
    target: typeof window !== 'undefined' ? window : undefined
  });

  const triggerIsScrolling = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: typeof window !== 'undefined' ? window : undefined
  });

  const retChildren = React.cloneElement(children, {
    elevation: triggerIsScrolling ? 4 : 0
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {retChildren}
    </Slide>
  );
}

export default HideOnScroll;
