import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useMemo
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';

interface SnackbarMessage {
  message: string;
  key: number;
  undoAction?: () => void;
  open: boolean;
}

interface SnackbarContextType {
  showMessage: (message: string, options?: { undoAction?: () => void }) => void;
}

const SnackbarContext = createContext<SnackbarContextType | null>(null);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [snackPack, setSnackPack] = useState<SnackbarMessage[]>([]);

  const showMessage = useCallback(
    (message: string, options: { undoAction?: () => void } = {}) => {
      setSnackPack((prev) => [
        ...prev,
        {
          message,
          key: new Date().getTime(),
          undoAction: options.undoAction,
          open: true
        }
      ]);
    },
    []
  );

  const handleClose =
    (key: number) => (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackPack((prev) =>
        prev.map((snack) =>
          snack.key === key ? { ...snack, open: false } : snack
        )
      );
    };

  const handleExited = (key: number) => () => {
    setSnackPack((prev) => prev.filter((snack) => snack.key !== key));
  };

  const handleUndo =
    (undoAction: (() => void) | undefined, key: number) => () => {
      undoAction?.();
      handleClose(key)();
      showMessage('Action undone');
    };

  const value = useMemo(() => ({ showMessage }), [showMessage]);

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {snackPack.map((snack) => (
        <Snackbar
          key={snack.key}
          open={snack.open}
          autoHideDuration={6000}
          onClose={handleClose(snack.key)}
          TransitionProps={{
            onExited: handleExited(snack.key),
            exit: true
          }}
          message={snack.message}
          action={
            snack.undoAction ? (
              <Button
                color="secondary"
                size="small"
                onClick={handleUndo(snack.undoAction, snack.key)}
                sx={{
                  color: '#fff'
                }}
              >
                UNDO
              </Button>
            ) : null
          }
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          TransitionComponent={Slide}
        />
      ))}
    </SnackbarContext.Provider>
  );
};
