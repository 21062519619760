/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios, { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';
import config from '@configFile';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: config.API_PREFIX
});

function useResendValidationEmail() {
  const mutationFn = async (email: string): Promise<void> => {
    await axiosInstance.post(`/api/resend-verification-email`, {
      email
    });
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('Error sending');
    }
  });
}

export default useResendValidationEmail;
