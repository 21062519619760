import React from 'react';
import {
  Typography,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent
} from '@mui/material';

const LoadingModal = () => {
  return (
    <Dialog
      open
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <CircularProgress
          color="primary"
          sx={{
            margin: '0 auto',
            marginBottom: '18px'
          }}
        />
        <Typography variant="body1" gutterBottom>
          Adding you to email lists... please wait...
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingModal;
