import _ from 'lodash';

import {
  List,
  ListItem,
  MakeListsState,
  ListAction,
  LISTS_CHANGE_VARIABLE,
  // LISTS_SAVE_LIST,
  // LISTS_DELETE_LIST,
  LISTS_NEW_LIST,
  // LISTS_FETCH_LISTS,
  LISTS_FETCHED_LISTS,
  LISTS_UPDATE_LIST,
  LISTS_LOADED_LIST,
  LISTS_BACK_TO_LISTS,
  LIST_VIEW_LOADED,
  LIST_VIEW_SET_CURRENT_SLUG
} from '../types';

const emptyList: List = {
  title: '',
  date: '',
  isLive: false,
  tagline: '',
  tags: [],
  slug: '',
  image: '',
  imageWidth: null,
  imageHeight: null,
  description: '',
  publishTime: null,
  modifiedTime: null,
  listItems: []
};

export const emptyListItem: ListItem = {
  listItemId: null,
  title: '',
  store: 'AMAZON',
  description: '',
  asin: null,
  image: '',
  imageWebp: null,
  brand: null
};

// Export for unit testing
const initialState: MakeListsState = {
  lists: [],
  currentMode: 'list',
  currentList: null,
  currentId: null,
  currentSlug: null
};

export default (state = initialState, action: ListAction) => {
  let newState = null;
  switch (action.type) {
    case LISTS_NEW_LIST:
      newState = _.cloneDeep(state);
      newState.currentId = -1;
      newState.currentList = { ...emptyList };
      newState.currentMode = 'new';
      return newState;
    case LISTS_LOADED_LIST:
      newState = _.cloneDeep(state);
      newState.currentId = action.list.id;
      newState.currentList = action.list;
      newState.currentMode = 'edit';
      return newState;
    case LISTS_BACK_TO_LISTS:
      newState = _.cloneDeep(state);
      newState.currentMode = 'list';
      newState.currentList = null;
      newState.currentId = null;
      newState.currentSlug = null;
      return newState;
    case LIST_VIEW_SET_CURRENT_SLUG:
      newState = _.cloneDeep(state);
      newState.currentSlug = action.currentSlug;
      return newState;
    case LIST_VIEW_LOADED:
      newState = _.cloneDeep(state);
      newState.currentId = action.list.id;
      newState.currentList = action.list;
      newState.currentMode = 'view';
      return newState;
    case LISTS_UPDATE_LIST:
      newState = _.cloneDeep(state);
      newState.currentList = action.data;
      return newState;
    case LISTS_CHANGE_VARIABLE:
      if (!action.variable) return state;
      const { variable } = action;
      const value = action.data;
      newState = _.cloneDeep(state);
      newState.currentList = {
        ...newState.currentList,
        [variable]: value
      };
      return newState;
    case LISTS_FETCHED_LISTS:
      newState = _.cloneDeep(state);
      newState.lists = action.listPreviews;
      newState.currentMode = 'list';
      return newState;
    default:
      return state;
  }
};
