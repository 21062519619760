/* eslint-disable */
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { renderRoutes } from 'react-router-config';
import { loadableReady } from '@loadable/component';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import { QueryClientProvider } from 'react-query';
import config from '@configFile';
import createEmotionCache from './createEmotionCache';
import { SnackbarProvider } from './hooks/useSnackbar';
import { LoginModalProvider } from './hooks/useLoginModal';
import theme, { darkTheme } from './theme/theme';
import queryClient from './queryClient';

import configureStore from './utils/configureStore';
import routes from './routes';

// Create the emotion cache
const cache = createEmotionCache();

// Get the initial state from server-side rendering
const initialState = window.__INITIAL_STATE__;
const { store, history } = configureStore({ initialState });

// Component that handles theme switching based on system preference
const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  // Utility function to get the value of a cookie by name
  const getCookieValue = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
    return null;
  };

  // Determine initial theme based on the system preference
  const systemPrefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [currentTheme, setCurrentTheme] = useState(systemPrefersDarkMode ? darkTheme : theme);

  useEffect(() => {
    // Read the cookie value for the theme
    const cookieTheme = getCookieValue('theme');
    const isDarkMode = currentTheme === darkTheme;

    // If the cookie is not set or doesn't match the current theme, update it
    if (!cookieTheme  && isDarkMode === true || (cookieTheme === 'dark' && isDarkMode === false) || (cookieTheme === 'light' && isDarkMode === true)) {
      document.cookie = `theme=${isDarkMode ? 'dark' : 'light'}; path=/;`;
      // Refresh the page to apply the correct theme if there's a mismatch
      window.location.reload();
    } else {
      document.cookie = `theme=${isDarkMode ? 'dark' : 'light'}; path=/;`;
    }

    // Function to update the theme based on preference
    const handleThemeChange = (e: MediaQueryListEvent) => {
      const newTheme = e.matches ? darkTheme : theme;
      setCurrentTheme(newTheme);
      document.cookie = `theme=${e.matches ? 'dark' : 'light'}; path=/;`;
    };

    // Add the event listener for changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', handleThemeChange);


    // Clean up the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleThemeChange);
    };
  }, [currentTheme]);

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
};


const render = (Routes: Array<object>) => {
  const renderMethod = (module as any).hot ? ReactDOM.render : ReactDOM.hydrate;

  renderMethod(
      <GoogleOAuthProvider clientId="135329434554-k4db2qolei4ej44835kv5hq05tupvaij.apps.googleusercontent.com">
        <SnackbarProvider>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <QueryClientProvider client={queryClient}>
                <CacheProvider value={cache}>
                    <StyledEngineProvider injectFirst>
                      <ThemeWrapper>
                        <LoginModalProvider>
                          {renderRoutes(Routes)}
                        </LoginModalProvider>
                      </ThemeWrapper>
                    </StyledEngineProvider>
                </CacheProvider>
              </QueryClientProvider>
            </ConnectedRouter>
          </Provider>
        </SnackbarProvider>
      </GoogleOAuthProvider>,
    document.getElementById('react-view')
  );
};

// loadable-component setup
loadableReady(() => {
  render(routes);
});

if ((module as any).hot) {
  // Enable webpack hot module replacement for routes
  (module as any).hot.accept('./routes', () => {
    try {
      const nextRoutes = require('./routes').default;

      render(nextRoutes);
    } catch (error) {
      console.error(`==> 😭  Routes hot reloading error ${error}`);
    }
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below.
function register() {
  if ('serviceWorker' in navigator && config.USE_SERVICE_WORKER) {
   // @ts-ignore
    window.self.__WB_DISABLE_DEV_LOGS = false;

    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/assets/sw.js')
        .then((registration) => {
          // console.log('SW registered: ', registration);

          registration.onupdatefound = () => {
            const installingWorker = registration.installing;

            if (!installingWorker) return;

            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  console.log(
                    'New content is available and will be used when all tabs for this page are closed. See https://bit.ly/CRA-PWA.',
                  );

                  if (!!window?.localStorage?.getItem('googleLogin') && window.confirm(`New app update is available!. Click OK to refresh`)) {
                    window.location.reload();
                  }
                } else {
                  console.log('Content is cached for offline use.');
                }
              }
            };
          };
        })
        .catch((registrationError) => {
          console.log('SW registration failed: ', registrationError);
        });
    });
  }
}

register();

// export function unregister() {
//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.ready
//       .then((registration) => registration.unregister())
//       .catch((err) => console.error(err.message));
//   }
// }