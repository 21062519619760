import React, { useCallback, useMemo } from 'react';
import {
  Box,
  ListItemText,
  List,
  ListItem,
  Switch,
  Typography
} from '@mui/material';
import { logPostHogEvent } from '@utils/index';
import {
  useGetUserMailingLists,
  useToggleMailingListSubscription,
  useGetPendingMailingListRemovals,
  useGetPendingMailingListAdditions
} from '@hooks/useEmail';
import trackUse from '@utils/trackUse';
import Loading from '@components/Loading';
import { emailListsNotHidden } from '@utils/mailingLists';

const EmailListUI = () => {
  const { data: mailingLists, isLoading: mailingListsLoading } =
    useGetUserMailingLists();
  const { data: pendingRemovals, isLoading: pendingRemovalsLoading } =
    useGetPendingMailingListRemovals();
  const { data: pendingAdditions, isLoading: pendingAdditionsLoading } =
    useGetPendingMailingListAdditions();
  const { mutate: toggleMailingListSubscription, isLoading } =
    useToggleMailingListSubscription();

  const pendingRemovalLists = useMemo(
    () =>
      (pendingRemovals || []).filter((list) =>
        (mailingLists || []).includes(list)
      ),
    [pendingRemovals, mailingLists]
  );

  const pendingAdditionLists = useMemo(
    () =>
      (pendingAdditions || []).filter(
        (list) => !(mailingLists || []).includes(list)
      ),
    [pendingAdditions, mailingLists]
  );

  const uiLoading =
    mailingListsLoading || pendingRemovalsLoading || pendingAdditionsLoading;

  const handleMailingListClick = useCallback(
    (mlId, isOnMailingList, displayName) =>
      (e: React.ChangeEvent<HTMLElement> | React.MouseEvent<HTMLElement>) => {
        e?.preventDefault();
        e?.stopPropagation();

        if (isLoading) return;

        toggleMailingListSubscription({
          mailingListId: mlId,
          shouldRemove: isOnMailingList
        });

        const action = isOnMailingList ? 'unsubscribe' : 'subscribe';
        const eventLabel = `${action}-${displayName}`;
        trackUse({ item: 'email-list', value: eventLabel, type: 'ACTION' });
        logPostHogEvent('email-list', { value: eventLabel, type: 'ACTION' });
      },
    [isLoading, toggleMailingListSubscription]
  );

  if (uiLoading) return <Loading />;

  return (
    <Box>
      <Box sx={{ padding: '8px 16px 0px 16px' }}>
        <Typography variant="h6">
          You are currently subscribed to {mailingLists?.length || 0} mailing
          list
          {mailingLists?.length === 1 ? '' : 's'}
        </Typography>
      </Box>
      <List>
        {emailListsNotHidden.map((ml) => {
          const isPendingRemoval = pendingRemovalLists.includes(ml.id);
          const isPendingAddition = pendingAdditionLists.includes(ml.id);
          const isChecked =
            isPendingRemoval ||
            isPendingAddition ||
            (mailingLists || []).includes(ml.id);
          const disabled = isLoading || isPendingRemoval || isPendingAddition;

          return (
            <ListItem
              key={ml.id}
              sx={{
                cursor: disabled ? 'not-allowed' : 'pointer',
                opacity: disabled ? 0.7 : 1
              }}
            >
              <ListItemText
                primary={`${ml.displayName}${
                  isPendingRemoval ? ' — Processing removal...' : ''
                }${isPendingAddition ? ' — Processing addition...' : ''}`}
                secondary={ml.displayDescription}
                onClick={handleMailingListClick(
                  ml.id,
                  isChecked,
                  ml.displayName
                )}
              />
              <Switch
                edge="end"
                onChange={handleMailingListClick(
                  ml.id,
                  isChecked,
                  ml.displayName
                )}
                disabled={disabled}
                checked={isChecked}
                inputProps={{ 'aria-labelledby': `switch-list-label-${ml.id}` }}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default EmailListUI;
