/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios, { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';
import config from '@configFile';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: config.API_PREFIX
});

export interface RecaptchaResponse {
  success: boolean;
  challenge_ts: string;
  hostname: string;
}

export function useVerifyRecapture() {
  const mutationFn = async (
    captchaValue: string
  ): Promise<RecaptchaResponse> => {
    const result = await axiosInstance.post(`/api/verify-not-robot`, {
      captchaValue
    });
    return result.data;
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('Error sending');
    }
  });
}
