export default {
  senderId: 5210710,
  unsubscribeGroups: {
    testDeals: 41457,
    bestSellingTopDeals: 41557,
    weekly: 41457,
    dailyDeals: 41687,
    hotDeals: 41688,
    promotions: 41689,
    catDeals: 41690,
    dogDeals: 41691,
    babyDeals: 41692
  },
  mailingLists: {
    test: '1b2bffb7-8f54-4d00-97eb-52d224b92dbc',
    bestSellingTopDeals: '43896209-4d97-4055-af1b-3ae8d36fbb14',
    catDeals: '19cdaf86-a61c-4ecc-82d9-57743db13644',
    dogDeals: 'ec5d08c6-cafb-47ee-932d-5c216fd7bb1d',
    hotDeals: '4b2e219a-c8ff-45e5-96be-f5733655345f',
    promotions: '835eb758-7760-4be6-bfa1-fd4eceb0be67',
    babyDeals: '4f6a7163-11ea-429e-860d-42225347011f',
    dailyDeals: '2f3f3e81-5389-438c-8b2f-36afd024f813'
  },
  sendgridJobTime: 5
};
