export const appName = 'Jungle Deals';
export const drawerWidth = 236;

const linkExclusions: { [key: string]: string[] | undefined } = {
  '/variations': ['/variations-searched'],
  '/blog': ['/feed']
};

export const isCurrentlyOnPage = (
  link: string | null,
  pathname: string | undefined | null
): boolean => {
  if (typeof pathname === 'undefined' || !link) {
    return link === '/';
  }

  const exclusions = linkExclusions[link];
  if (exclusions) {
    return (
      pathname.includes(link) &&
      !exclusions.some((exclusion) => pathname.includes(exclusion))
    );
  }

  if (link === '/') {
    const homePaths = ['', '/', '/deals'];
    return homePaths.includes(pathname) || pathname.includes('/deal/');
  }

  return pathname.includes(link);
};
