import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Alert
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '@configFile';
import { useVerifyRecapture, RecaptchaResponse } from '@hooks/useRecaptcha';
import { useGetUserMailingLists, useSignUpMailingList } from '@hooks/useEmail';
import { useGetUserData } from '@hooks/useGetUserData';
import { isValidEmail, isNameProhibited } from '@utils/index';
import trackUse from '@utils/trackUse';
import { getMailingListById } from '@utils/mailingLists';
import { LoadingSmall } from '@components/Loading';
import CompleteSignUpOrLogin from '@components/CompleteSignUpOrLogin';
import Signin from '@components/Signin';

interface AddMailingListProps {
  mailingListId: string;
}

const AddMailingList = ({ mailingListId }: AddMailingListProps) => {
  const recaptcha = React.useRef();
  const mailingList = getMailingListById(mailingListId);
  const { data: user, isLoading: isLoadingUser } = useGetUserData();
  const [hasVerified, setHasVerified] = useState<boolean>(false);
  const [hasAccount, setHasAccount] = useState<boolean>(false);
  const [hasSignedUp, setHasSignedUp] = useState<boolean>(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const { mutate: signUpMailingList, isLoading } = useSignUpMailingList();
  const { mutate: verifyRecaptcha } = useVerifyRecapture();
  const { data: mailingLists, isLoading: isLoadingMailingLists } =
    useGetUserMailingLists();

  const signedUpMailingLists = mailingLists || [];
  const isAlreadySignedUp = signedUpMailingLists.includes(mailingListId);

  useEffect(() => {
    if (user?.email && user?.hasVerifiedEmail) {
      setEmail(user.email);
      setFirstName(user.firstName);
      setLastName(user.lastName);
    }
  }, [user]);

  const onChange = (value: string) => {
    verifyRecaptcha(value, {
      onError: () => {
        // eslint-disable-next-line no-alert
        alert('Verification failed, please try again');
      },
      onSuccess: (data: RecaptchaResponse) => {
        if (data?.success) {
          setHasVerified(true);
        } else {
          // eslint-disable-next-line no-alert
          alert('Please verify you are not a robot');
        }
      }
    });
  };

  const isSubmitButtonEnabled = () => {
    if (!isValidEmail(email)) return false;
    if (firstName.trim().length < 2) return false;
    if (lastName.trim().length < 2) return false;
    if (!hasVerified) return false;
    if (isNameProhibited(firstName.toLowerCase(), lastName.toLowerCase())) {
      trackUse({
        item: 'prohibited-name-wise-guy',
        value: `${firstName.toLowerCase()} ${lastName.toLowerCase()} ${mailingListId}`,
        type: 'SECURITY'
      });
      return false;
    }
    return true;
  };

  const handleSubmitClick = async () => {
    if (!isSubmitButtonEnabled()) return;
    signUpMailingList(
      {
        email,
        firstName,
        lastName,
        mailingListId
      },
      {
        onSuccess: (ret) => {
          const { data } = ret;
          setHasSignedUp(true);
          if ((!data.isLoggedIn || user) && !!data.hasAccount) {
            setHasAccount(true);
          } else {
            setHasAccount(false);
          }
        }
      }
    );
  };

  if (
    isAlreadySignedUp ||
    isLoadingUser ||
    isLoadingMailingLists ||
    !mailingList
  ) {
    return null;
  }

  if (hasSignedUp) {
    if (user?.email && user?.hasVerifiedEmail) {
      return (
        <Alert
          severity="success"
          sx={{
            margin: '1rem'
          }}
        >
          You have successfully signed up to the &quot;{mailingList.displayName}
          &quot; mailing list!
        </Alert>
      );
    }

    return (
      <Box marginBottom={hasAccount ? '1rem' : '0'}>
        {hasAccount ? (
          <Signin showDontHaveAccount={false} />
        ) : (
          <CompleteSignUpOrLogin hasJustSetUpEmail />
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Box component="form" noValidate autoComplete="off" margin="1.75rem 1rem">
        <Typography
          variant="h2"
          component="div"
          sx={{
            marginBottom: '1rem',
            fontSize: '32px',
            fontWeight: 700,
            color: '#546b81'
          }}
        >
          {mailingList.signUpPrompt.title}
        </Typography>
        <Typography
          variant="body1"
          component="div"
          sx={{ marginBottom: '1rem', color: '#546b81' }}
        >
          {mailingList.signUpPrompt.subtitle}
        </Typography>
        {isLoading ? (
          <Box>
            <LoadingSmall text="Signing you up..." />
          </Box>
        ) : (
          <Box>
            <FormControl margin="normal" size="small" fullWidth>
              <InputLabel htmlFor="first-name">First Name</InputLabel>
              <OutlinedInput
                id="first-name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label="First Name"
                required
                placeholder="Please enter your first name"
                disabled={isLoading}
              />
            </FormControl>
            <FormControl margin="normal" size="small" fullWidth>
              <InputLabel htmlFor="last-name">Last Name</InputLabel>
              <OutlinedInput
                id="last-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                label="Last Name"
                required
                placeholder="Please enter your last name"
                disabled={isLoading}
              />
            </FormControl>
            <FormControl margin="normal" size="small" fullWidth>
              <InputLabel htmlFor="email">Email</InputLabel>
              <OutlinedInput
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
                required
                placeholder="Please enter your email"
                disabled={isLoading}
              />
            </FormControl>
            <Box margin="1rem 0">
              <ReCAPTCHA
                ref={recaptcha}
                sitekey={config.RECAPTCHA_SITE_KEY}
                onChange={onChange}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                disabled={!isSubmitButtonEnabled() || isLoading}
                onClick={handleSubmitClick}
              >
                Submit
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AddMailingList;
