/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Box, Link, Alert, AlertTitle, Card, CardContent } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import useResendEmailValidation from '@hooks/useResendEmailValidation';
import { useGetUserData } from '@hooks/useGetUserData';
import { useSnackbar } from '@hooks/useSnackbar';

const VerifyBanner = () => {
  const [isSending, setIsSending] = useState(false);
  const { mutate: resend } = useResendEmailValidation();
  const { showMessage } = useSnackbar();
  const { data: userData, isLoading: isLoadingUserData } = useGetUserData();

  useEffect(() => {
    if (isSending) {
      resend(userData?.email);
      showMessage('Verification email re-sent!');
      setTimeout(() => {
        setIsSending(false);
      }, 3000);
    }
  }, [isSending]);

  if (
    isLoadingUserData ||
    !userData ||
    !userData?.needsEmailVerification ||
    !userData?.email
  ) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding={1}
      color="primary.contrastText"
    >
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <Alert variant="outlined" severity="warning" style={{ marginTop: 4 }}>
            <AlertTitle>Email not verified</AlertTitle>
            You have not yet verified your email.{' '}
            <Link
              component={RouterLink}
              to="#"
              onClick={() => {
                setIsSending(true);
              }}
              style={{
                cursor: 'pointer'
              }}
            >
              Click here
            </Link>{' '}
            to resend the verification email. If you have verified your email on
            another device please logout and log back in to refresh your
            security cookie.
          </Alert>
        </CardContent>
      </Card>
    </Box>
  );
};

export default VerifyBanner;
