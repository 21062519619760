/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import queryClient from '../queryClient';
import { SiteEventType } from '../types';
import { useSnackbar } from './useSnackbar';

export function useGetCurrentSiteEvents(): UseQueryResult<SiteEventType[]> {
  const queryFn = async (): Promise<SiteEventType[]> => {
    const result = await axios.get(`/api/current-site-events`);
    return result.data;
  };

  return useQuery<SiteEventType[], Error>(
    `current-site-events`,
    () => queryFn(),
    {
      onError: () => {
        console.error(
          'Something went wrong while fetching the current site events'
        );
      }
    }
  );
}

export function useGetSiteEvents(): UseQueryResult<SiteEventType[]> {
  const queryFn = async (): Promise<SiteEventType[]> => {
    const result = await axios.get(`/api/dashboard/site-events/get`);
    return result.data;
  };

  return useQuery<SiteEventType[], Error>(`site-events`, () => queryFn(), {
    onError: () => {
      console.error('Something went wrong while fetching the site events');
    }
  });
}

export function useDeleteSiteEvent() {
  const { showMessage } = useSnackbar();
  interface RequestDeleteData {
    id: number;
  }

  const mutationFn = async ({ id }: RequestDeleteData): Promise<null> => {
    return axios.delete(`/api/dashboard/site-events/delete/${id}`);
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`site-events`);
      queryClient.refetchQueries(`current-site-events`);
      showMessage('Site event deleted');
    },
    onError: () => {
      console.error('error deleting site event');
    }
  });
}

export function useUpdateSiteEvent() {
  const { showMessage } = useSnackbar();

  const mutationFn = async (
    event: Omit<SiteEventType, 'createdAt' | 'updatedAt'>
  ): Promise<null> => {
    const { id, ...siteEvent } = event;
    return axios.post(`/api/dashboard/site-events/update/${id}`, siteEvent);
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`site-events`);
      queryClient.refetchQueries(`current-site-events`);
      showMessage('Site event updated');
    },
    onError: () => {
      console.error('error updating site event');
    }
  });
}

export function useAddSiteEvent() {
  const { showMessage } = useSnackbar();
  interface RequestData
    extends Omit<SiteEventType, 'id' | 'createdAt' | 'updatedAt'> {}

  const mutationFn = async (eventData: RequestData) => {
    return axios.post(`/api/dashboard/site-events/add`, eventData);
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`site-events`);
      queryClient.refetchQueries(`current-site-events`);
      showMessage('Site event added');
    },
    onError: () => {
      console.error('error adding site event');
    }
  });
}
