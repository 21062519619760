import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useServiceWorkerUpdate = () => {
  const location = useLocation();

  useEffect(() => {
    // This function checks if a new service worker is available
    const checkForServiceWorkerUpdate = () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          if (registration.waiting) {
            // A new service worker is waiting to take control
            if (
              !!window?.localStorage?.getItem('googleLogin') &&
              // eslint-disable-next-line no-alert
              window.confirm(
                'New content is available. Would you like to reload the page to get the latest updates?'
              )
            ) {
              // Tell the new service worker to take control
              registration.waiting.postMessage({ type: 'SKIP_WAITING' });
              window.location.reload();
            }
          }
        });
      }
    };

    // Trigger check for service worker update on each navigation event
    checkForServiceWorkerUpdate();

    // Add a global error handler for ChunkLoadError
    const handleChunkLoadError = (error: any) => {
      if (
        error.message &&
        (error.message.includes('ChunkLoadError') ||
          error.message.includes('Loading chunk'))
      ) {
        window.location.reload();
      }
    };

    window.addEventListener('error', handleChunkLoadError);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('error', handleChunkLoadError);
    };
  }, [location]);
};

export default useServiceWorkerUpdate;
