import React from 'react';
import {
  Card,
  Box,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar
} from '@mui/material';
import { format, formatDistance, isAfter, isWithinInterval } from 'date-fns';
import { addAffiliateTagToLink } from '@utils/index';
import config from '@configFile';
import Loading from '@components/Loading';
import ShowIcon from '@components/ShowIcon';
import { rotateAnimation } from '@utils/theme';
import { SiteEventType } from '@types';
import { MonetizingLink } from '..';

type EventsListProps = {
  events: SiteEventType[];
  isMobile?: boolean;
  isLoading?: boolean;
};

const EventsList: React.FC<EventsListProps> = ({
  events,
  isMobile = false,
  isLoading = false
}) => {
  const now = new Date();

  const currentEvents = events.filter((event) =>
    isWithinInterval(now, {
      start: new Date(event.start),
      end: new Date(event.end)
    })
  );

  const upcomingEvents = events.filter((event) =>
    isAfter(new Date(event.start), now)
  );

  const renderEvent = (event: SiteEventType, isFuture = false) => (
    <ListItem key={event.title}>
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: event.color }}>
          <ShowIcon
            icon={event.icon}
            sx={
              event.isLeadEvent
                ? {
                    ...rotateAnimation,
                    animation: 'rotate 6s linear infinite'
                  }
                : {}
            }
          />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          event.link && !isFuture ? (
            <MonetizingLink
              href={addAffiliateTagToLink(
                event.link,
                config.AFFILIATE_TAGS.JD_SPECIAL_OFFERS
              )}
              target="_blank"
              tag={config.AFFILIATE_TAGS.JD_SPECIAL_OFFERS}
              item="upcoming-event"
              clickType={addAffiliateTagToLink(
                event.link,
                config.AFFILIATE_TAGS.JD_SPECIAL_OFFERS
              )}
              underline="always"
            >
              {event.title}
            </MonetizingLink>
          ) : (
            event.title
          )
        }
        secondary={`${format(new Date(event.start), 'MMM dd, yyyy')} — ${format(
          new Date(event.end),
          'MMM dd, yyyy'
        )}${
          isFuture
            ? `, starts in ${formatDistance(new Date(), new Date(event.start))}`
            : `, ends in ${formatDistance(new Date(), new Date(event.end))}`
        }`}
      />
    </ListItem>
  );

  return (
    <Box
      padding={isMobile ? '1rem' : '0'}
      sx={{
        backgroundColor: isMobile ? '#80808029' : 'inherit'
      }}
    >
      <Card
        elevation={isMobile ? 4 : 1}
        sx={{
          margin: '1rem'
        }}
      >
        <CardContent>
          <Typography variant="h6" component="div" gutterBottom>
            Current Events
          </Typography>
          {isLoading ? (
            <Loading />
          ) : (
            <List dense>
              {currentEvents.length > 0 ? (
                currentEvents.map((event) => renderEvent(event, false))
              ) : (
                <ListItem>
                  <ListItemText primary="No current events" />
                </ListItem>
              )}
            </List>
          )}
          <Divider />
          <Box marginTop="12px">
            <Typography variant="h6" component="div" gutterBottom>
              Upcoming Events
            </Typography>
          </Box>
          {isLoading ? (
            <Loading />
          ) : (
            <List dense>
              {upcomingEvents.length > 0 ? (
                upcomingEvents.map((event) => renderEvent(event, true))
              ) : (
                <ListItem>
                  <ListItemText primary="No upcoming events" />
                </ListItem>
              )}
            </List>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default EventsList;
