import React from 'react';

import { Box, Typography, Button, TextField } from '@mui/material';

import useLocalStorage from '@hooks/useLocalStorage';
import trackUse from '@utils/trackUse';
import { useSignup } from '../../pages/Signup/useSignup';

const CompleteSignUpOrLogin = ({
  hasJustSetUpEmail = false
}: {
  hasJustSetUpEmail?: boolean;
}) => {
  const { mutate: signup } = useSignup();
  const [firstName] = useLocalStorage('form_firstName', '');
  const [lastName] = useLocalStorage('form_lastName', '');
  const [email] = useLocalStorage('form_email', '');
  const [password, setPassword] = React.useState<string>('');
  const [hasAccount, setHasAccount] = React.useState<boolean>(false);

  if (!firstName || !lastName || !email) {
    return null;
  }

  const getAccount = () => {
    // get the account if it exists
    signup(
      {
        email: email.trim(),
        password: password.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim()
      },
      {
        onSuccess: () => {
          setHasAccount(true);
          trackUse({
            item: `signup-complete-${
              !hasJustSetUpEmail ? 'no-email' : 'email'
            }`,
            value: 'signup-complete',
            type: 'ACTION'
          });
        },
        onError: () => {
          // eslint-disable-next-line no-alert
          alert('There was an error signing up, please try again later');
        }
      }
    );
  };

  if (hasAccount) {
    return (
      <Box
        sx={{
          margin: '1rem'
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="body1" gutterBottom>
            We just sent you an email to verify your account. Please click the
            link in the email to verify your account and automatically login.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        margin: '1rem'
      }}
    >
      <Box sx={{ width: '100%' }}>
        {hasJustSetUpEmail ? (
          <Typography variant="body1" gutterBottom>
            You&apos;re all set up! You should be receiving your first email
            soon!
          </Typography>
        ) : null}
        <Box>
          <Typography variant="body1" gutterBottom>
            It looks like you don&apos;t have an account with us yet, it&apos;s
            free and unlocks premium features. If you would like to make an
            account, please enter a password below.
          </Typography>
          <Box
            sx={{
              margin: '1rem 0'
            }}
          >
            <TextField
              label="Password"
              size="small"
              variant="outlined"
              fullWidth
              margin="normal"
              type="password"
              style={{
                margin: '8px 0'
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              helperText="Please enter a password with at least 12 characters"
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={getAccount}
              disabled={password.trim().length < 12}
            >
              Sign up
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompleteSignUpOrLogin;
