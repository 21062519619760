import React from 'react';
import trackUse from '@utils/trackUse';
import { getASINFromURL } from '@utils/URL';

export const trackLinkClick = (
  e: React.MouseEvent<HTMLAnchorElement>,
  location: string
) => {
  trackUse({
    item: location,
    value: e.currentTarget.getAttribute('href'),
    type: 'CLICK'
  });
};

export const logPostHogEvent = (
  eventName: string,
  extraItems?: Record<string, any>
) => {
  // @ts-ignore
  if (window?.posthog) {
    // @ts-ignore
    window?.posthog?.capture(eventName, {
      ...(extraItems || {})
    });
  }
};

export const logPostHogClick = (
  href: string,
  clickType: string,
  tag: string,
  item: string,
  extraItems?: Record<string, any>
) => {
  // @ts-ignore
  if (window?.posthog) {
    // @ts-ignore
    window?.posthog?.capture('monetizing_click', {
      ASIN: getASINFromURL(href) || '',
      tag,
      clickType,
      value: href,
      type: 'CLICK',
      item: item || clickType || tag,
      ...(extraItems || {})
    });
  }
};
