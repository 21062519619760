import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import home from './home';
import lists from './lists';

export default (history: History) =>
  combineReducers({
    // Register reducers here
    home,
    lists,
    router: connectRouter(history)
  });
