import React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

const Ribbon = styled(Box)({
  fontSize: '24px',
  fontWeight: 'normal',
  color: '#fff',
  '--s': '2.6em' /* the ribbon size */,
  '--d': '.3em' /* the depth */,
  '--c': '.8em' /* the cutout part */,
  padding: 'var(--d) calc(var(--s) + 1em) 0',
  lineHeight: '1.7',
  background: `
    conic-gradient(from  45deg at left  var(--s) top var(--d),
     #0008 12.5%,#0000 0 37.5%,#0004 0) 0   /50% 100% no-repeat,
    conic-gradient(from -45deg at right var(--s) top var(--d),
     #0004 62.5%,#0000 0 87.5%,#0008 0) 100%/50% 100% no-repeat`,
  clipPath: `
    polygon(0 0,calc(var(--s) + var(--d)) 0,calc(var(--s) + var(--d)) var(--d),calc(100% - var(--s) - var(--d)) var(--d),calc(100% - var(--s) - var(--d)) 0,100% 0, calc(100% - var(--c)) calc(50% - var(--d)/2),100% calc(100% - var(--d)),calc(100% - var(--s)) calc(100% - var(--d)),calc(100% - var(--s)) 100%,var(--s) 100%,var(--s) calc(100% - var(--d)),0 calc(100% - var(--d)),var(--c) calc(50% - var(--d)/2))`,
  backgroundColor: '#2888b4' /* the main color */,
  width: 'fit-content',
  fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,

  // width smaller than 600 px
  '@media (max-width: 600px)': {
    fontSize: '19px',
    fontWeight: 'normal',
    color: '#fff',
    '--s': '2em' /* the ribbon size */,
    '--d': '.3em' /* the depth */,
    '--c': '.8em' /* the cutout part */,
    padding: 'var(--d) calc(var(--s) + .5em) 0',
    lineHeight: '2.1',
    background: `
          conic-gradient(from  45deg at left  var(--s) top var(--d),
           #0008 12.5%,#0000 0 37.5%,#0004 0) 0   /50% 100% no-repeat,
          conic-gradient(from -45deg at right var(--s) top var(--d),
           #0004 62.5%,#0000 0 87.5%,#0008 0) 100%/50% 100% no-repeat`,
    clipPath: `
          polygon(0 0,calc(var(--s) + var(--d)) 0,calc(var(--s) + var(--d)) var(--d),calc(100% - var(--s) - var(--d)) var(--d),calc(100% - var(--s) - var(--d)) 0,100% 0, calc(100% - var(--c)) calc(50% - var(--d)/2),100% calc(100% - var(--d)),calc(100% - var(--s)) calc(100% - var(--d)),calc(100% - var(--s)) 100%,var(--s) 100%,var(--s) calc(100% - var(--d)),0 calc(100% - var(--d)),var(--c) calc(50% - var(--d)/2))`,
    backgroundColor: '#2888b4' /* the main color */,
    maxWidth: '100%'
  }
});

const PrimeRibbon: React.FC = () => {
  return (
    <Link
      to="/primeday"
      style={{
        textDecoration: 'none'
      }}
    >
      <Ribbon>It&apos;s Amazon Prime Day!</Ribbon>
    </Link>
  );
};

export default PrimeRibbon;
