export const iconButtonHoverRaiseStyle = {
  transform: 'translateY(0) ',
  transition: '0.2s',
  '& svg': {
    transition: '0.2s'
  },
  '&:hover, &:focus': {
    transform: 'translateY(-4px)',
    '& svg:last-of-type': {
      right: 0,
      opacity: 1
    }
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    height: '80%',
    display: 'block',
    left: 0,
    width: '1px'
  }
};

export const standardTextStyle = {
  marginTop: '20px',
  marginBottom: '0px',
  whiteSpace: 'pre-wrap',
  fontSize: '14px',
  '@media (max-width: 540px)': {
    fontSize: '14px'
  }
};

export const standardLinkStyle = {
  ...standardTextStyle,
  // hover
  '&:hover': {
    color: '#c9cf6a',
    transition: 'color .5s'
  }
};
