import React from 'react';
import { FaTiktok } from 'react-icons/fa';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Box, IconButton, Tooltip } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useTheme } from '@mui/system';
import { trackLinkClick } from '@utils/index';
import { iconButtonHoverRaiseStyle } from '@utils/styles';

const SocialIcons = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const socialUrls = [
    {
      name: 'Facebook Group',
      url: 'https://www.facebook.com/groups/Jungledealsblog/',
      icon: (
        <FacebookIcon
          sx={{
            color: isDarkMode ? '#8b9dc3' : '#3b5998' // Lighter color in dark mode
          }}
        />
      )
    },
    {
      name: 'Facebook Page',
      url: 'https://www.facebook.com/JungleDealsBlog/',
      icon: (
        <FacebookIcon
          sx={{
            color: isDarkMode ? '#8b9dc3' : '#3b5998' // Lighter color in dark mode
          }}
        />
      )
    },
    {
      name: 'Follow on TikTok',
      url: 'https://www.tiktok.com/@jungledealsblog',
      icon: <FaTiktok />
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/jungle.deals/',
      icon: (
        <InstagramIcon
          sx={{
            color: '#e95950'
          }}
        />
      )
    }
  ];

  return (
    <Box>
      {socialUrls.map((socialUrl) => (
        <Tooltip title={socialUrl.name} key={socialUrl.name} placement="top">
          <IconButton
            aria-label={socialUrl.name}
            href={socialUrl.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
              trackLinkClick(e, `social-icons-${socialUrl.name}`)
            }
            sx={iconButtonHoverRaiseStyle}
          >
            {socialUrl.icon}
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  );
};

export default SocialIcons;
