export const ringingAnimation = {
  '@keyframes ring': {
    '0%': { transform: 'rotateZ(0)' },
    '1%': { transform: 'rotateZ(30deg)' },
    '3%': { transform: 'rotateZ(-28deg)' },
    '5%': { transform: 'rotateZ(34deg)' },
    '7%': { transform: 'rotateZ(-32deg)' },
    '9%': { transform: 'rotateZ(30deg)' },
    '11%': { transform: 'rotateZ(-28deg)' },
    '13%': { transform: 'rotateZ(26deg)' },
    '15%': { transform: 'rotateZ(-24deg)' },
    '17%': { transform: 'rotateZ(22deg)' },
    '19%': { transform: 'rotateZ(-20deg)' },
    '21%': { transform: 'rotateZ(18deg)' },
    '23%': { transform: 'rotateZ(-16deg)' },
    '25%': { transform: 'rotateZ(14deg)' },
    '27%': { transform: 'rotateZ(-12deg)' },
    '29%': { transform: 'rotateZ(10deg)' },
    '31%': { transform: 'rotateZ(-8deg)' },
    '33%': { transform: 'rotateZ(6deg)' },
    '35%': { transform: 'rotateZ(-4deg)' },
    '37%': { transform: 'rotateZ(2deg)' },
    '39%': { transform: 'rotateZ(-1deg)' },
    '41%': { transform: 'rotateZ(1deg)' },
    '43%': { transform: 'rotateZ(0)' },
    '100%': { transform: 'rotateZ(0)' }
  }
};

export const rotateAnimation = {
  '@keyframes rotate': {
    '0%': { transform: 'rotate(0deg)' },

    '100%': { transform: 'rotateZ(360deg)' }
  }
};

export const wipeInAnimation = {
  '@keyframes wipeIn': {
    from: {
      clipPath: 'polygon(0 0, 0% 0, 0% 100%, 0 100%)'
    },
    to: {
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)'
    }
  }
};

export const bounceAnimation = {
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'translateY(0)',
      animationTimingFunction: 'cubic-bezier(0.8, 0, 1, 1)'
    },
    '50%': {
      transform: 'translateY(-8px)',
      animationTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)'
    }
  }
};

export const lightningAnimation = {
  '@keyframes lightning': {
    '0%': {
      opacity: 0.5
    },
    '50%': {
      opacity: 1
    },
    '100%': {
      opacity: 0.5
    }
  }
};
