/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { Metric } from '../types';
import { useGetUserData } from './useGetUserData';

export function useGetMetrics(): UseQueryResult<Metric[]> {
  const { data: userData } = useGetUserData();

  const queryFn = async (): Promise<Metric[]> => {
    const result = await axios.get(`/api/dashboard/get-metrics`);
    return result.data;
  };

  return useQuery<Metric[], Error>(`site-metrics`, () => queryFn(), {
    enabled: !!userData?.isAdmin,
    // update every 1 min
    refetchInterval: 60 * 1000,
    onError: () => {
      console.error('Something went wrong while fetching the site metrics');
    }
  });
}
