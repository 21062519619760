import { useState, useEffect } from 'react';

const useVisibilityChange = (): boolean => {
  // Initialize the state with `true` as a default.
  // The correct visibility state will be established once the component is mounted in the browser.
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    // Check if the window and document objects are available (client-side)
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      return;
    }

    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    // Setup the event listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Update the initial state based on the current visibility
    setIsVisible(!document.hidden);

    // Cleanup function
    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isVisible;
};

export default useVisibilityChange;
