import { QueryClient } from 'react-query';
import config from '@configFile';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: config.USE_REACT_QUERY_CACHE ? 60 * 5 * 1000 : 1000
    }
  }
});

export default queryClient;
