/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useMutation } from 'react-query';
import queryClient from '../queryClient';
import { useSnackbar } from './useSnackbar';

export function useSignin() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    email?: string;
    password?: string;
    credential?: string;
  }

  const mutationFn = async ({
    email,
    password,
    credential
  }: RequestData): Promise<{
    hasAccount: boolean;
    isSuspended: boolean;
  }> => {
    const { data } = await axios.post(`/api/user-signin`, {
      email,
      password,
      credential
    });

    queryClient.refetchQueries('user-data');

    return data;
  };

  return useMutation(mutationFn, {
    onError: () => {
      showMessage(`Error logging in!`);
    },
    onSuccess: (data) => {
      if (!data.isSuspended) {
        showMessage(`Successfully logged in!`);
      }
    }
  });
}
