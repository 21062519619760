export default {
  jdbPurple: '#392e50',
  jdbLightPurple: '#BDA4F4',
  red: '#b30000',
  lightRed: '#ff8a8a',
  primeSelected: '#35baf6',
  earlyEventDeal: '#c30076',
  gold: '#f9a825',
  subscribeSave: '#00796b',
  subscribeSaveDark: '#13B9A5',
  coupon: '#1565c0',
  couponDark: '#5385e9',
  promotion: '#ad1457',
  extraDeal: '#4a148c',
  linkColor: '#9a5691',
  // old variation color: #631976
  variation: '#795548',
  trending: '#C25700',
  trendingDark: '#C25700',
  trendingRibbonDark: '#943b00',
  belowAverage: '#1a237e',
  highReview: '#38803A',
  expired: '#d50000',
  favorite: '#e02b50',
  favoriteDark: '#d456a4',
  favoriteRibbonDark: '#910d5f',
  popular: 'linear-gradient(-225deg, #1e5799 0%, #27a38f 100%)',
  brand: 'linear-gradient(to right, #834d9b, #d04ed6)',
  list: 'linear-gradient(to right, #ec6f66, #f3a183)',
  reviews: `linear-gradient(to right, #7474bf, #348ac7)`,
  giftCards: `linear-gradient(to right, #232526, #414345)`
};
