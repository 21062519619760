/*
 * Due to this known issue: https://github.com/smooth-code/loadable-components/issues/173
 * Use .js extension for code-splitting file
 */

import React from 'react';
import loadable from '@loadable/component';

import { Loading, ErrorBoundary, forceReloadOnError } from '../../components';

const Users = loadable(() => import('./Users'), {
  fallback: <Loading />,
  onError: forceReloadOnError
});

export default (props) => (
  <ErrorBoundary>
    <Users {...props} />
  </ErrorBoundary>
);
