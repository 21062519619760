import CelebrationIcon from '@mui/icons-material/Celebration';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import SellIcon from '@mui/icons-material/Sell';
import {
  isPrimeDay,
  isLaborDay,
  isBeforeLaborDay,
  isBeforePrimeDay,
  isBeforeCyberMonday,
  getDaysBetweenNowAndDate,
  displayTwelveDaysOfDeals,
  isCyberMonday,
  displayShippingCutoffMessage,
  isBlackFridayDealsWeek,
  isBlackFriday,
  isBeforeBlackFriday
} from '@utils/index';
import config from '@configFile';

import colors from './colors';

interface EventStrObj {
  label: string;
  value: string;
  showCountdown: boolean;
  isEarlySale: boolean;
  listingMode: string;

  dealHighlightColor: string;
  chipSelectedColor: string;
  Icon: React.ElementType;
  iconName: string;
  // used for blog page title
  prefix?: string;
  shouldShowDeals: boolean;
  eventStartDate?: string;
  overrideBannerBgColor?: string;
  overrideCountdownMessage?: string;
  overrideChipLabel?: string;
  overrideChipColor?: string;
  overrideChipSelectedColor?: string;
  overrideChipColorDarkMode?: string;
  overrideChipSelectedColorDarkMode?: string;
  overrideRibbonLabel?: string;
  overrideEmailLinksNumber?: number;
  overrideEmailEventLink?: string;
  updatePageDealItemsForAllPageDeals?: boolean;
  updateEligiblePageDealOnceEvery30Mins?: boolean;
  showSnowFall?: boolean;
  showBannerConfetti?: boolean;
  // Should recheck staged deals at midnight
  recheckStagedDeals?: boolean;
  // This is to allow me to send a custom email if I want to
  // or to check things display okay
  suspendDailyEmail?: boolean;
  saleLink?: string;
  // custom affiliate tag
  affiliateTag?: string;
  // whether to sho the prifix
  showSitePrefix?: boolean;
  // whether to override the daily image that gets sent out at 6am
  shouldOverrideCollageDailyImage: boolean;
  // The Amazon API on the variations page may not give accurate results
  shouldShowAPINotice?: boolean;
  showNavLinkOption?: boolean;
  navLinkTitle?: string;
  navLinkHref?: string;
  navLinkAnimated?: boolean;
  showRibbonsAtFullOpacity?: boolean;
}

const currentYear = new Date().getFullYear();

export const getCurrentDealEventStrObj = (): EventStrObj | null => {
  if (isPrimeDay() || config.IS_PRIME_RUN_UP) {
    return {
      label: isPrimeDay() ? 'Prime Day' : 'Early Prime Day',
      value: 'PRIME_DAY',
      showCountdown: true,
      showBannerConfetti: isPrimeDay(),
      eventStartDate: config.DATES.PRIME_DAY_START,
      isEarlySale: config.SHOW_EARLY_DEALS || !isPrimeDay(),
      listingMode: `PRIME_DAY_${currentYear}`,
      dealHighlightColor: colors.jdbPurple,
      chipSelectedColor: colors.primeSelected,
      Icon: CelebrationIcon,
      iconName: 'CelebrationIcon',
      prefix: isPrimeDay() ? 'Prime Day' : 'Early Prime Day',
      recheckStagedDeals: isPrimeDay(),
      showSitePrefix: true,
      shouldShowDeals: true,
      shouldOverrideCollageDailyImage: !!isPrimeDay(),
      shouldShowAPINotice: isPrimeDay(),
      overrideEmailEventLink: isPrimeDay()
        ? `https://www.jungle.deals/primeDay`
        : undefined,
      showNavLinkOption: isPrimeDay(),
      navLinkTitle: 'Prime Day',
      navLinkHref: '/primeday',
      showRibbonsAtFullOpacity: isPrimeDay()
    };
  }

  if (isLaborDay()) {
    return {
      label: 'Labor Day',
      value: 'LABOR_DAY',
      showCountdown: true,
      eventStartDate: config.DATES.LABOR_DAY_START,
      isEarlySale: false,
      listingMode: `LABOR_DAY_${currentYear}`,
      dealHighlightColor: '#000000',
      chipSelectedColor: '#FFFFFF',
      Icon: LoyaltyIcon,
      iconName: 'LoyaltyIcon',
      shouldShowDeals: true,
      showSitePrefix: true,
      shouldOverrideCollageDailyImage: false
    };
  }

  if (
    (isBlackFriday() ||
      isBlackFridayDealsWeek() ||
      (config.SHOW_EARLY_DEALS && isBeforeBlackFriday())) &&
    !isCyberMonday()
  ) {
    return {
      label: isBlackFriday() ? 'Black Friday' : 'Black Friday Week',
      value: 'BLACK_FRIDAY',
      shouldShowDeals: isBlackFridayDealsWeek() || isBlackFriday(),
      showCountdown: true,
      showSnowFall: true,
      overrideBannerBgColor: '#0F8A5F',
      // overrideBannerBgColor: '#ff6101',
      eventStartDate: config.DATES.BLACK_FRIDAY_WEEK_START,
      isEarlySale: isBeforeBlackFriday(),
      saleLink: config.EVENT_LINKS.BLACK_FRIDAY,
      affiliateTag: config.AFFILIATE_TAGS.BLACK_FRIDAY_WEEK,
      overrideChipLabel: isBeforeBlackFriday()
        ? 'Early Black Friday'
        : 'Black Friday',
      overrideChipColor: '#000000',
      overrideChipSelectedColor: '#FFFFFF',
      overrideChipColorDarkMode: '#FFFFFF',
      overrideChipSelectedColorDarkMode: '#FFFFFF',
      updatePageDealItemsForAllPageDeals: isBlackFriday(),
      overrideEmailEventLink: isBlackFriday()
        ? `https://www.jungle.deals/black-friday`
        : undefined,
      recheckStagedDeals: isBlackFriday(),
      updateEligiblePageDealOnceEvery30Mins: isBlackFriday(),
      overrideEmailLinksNumber: isBlackFriday() ? 20 : 4,
      shouldShowAPINotice: isBlackFriday(),
      overrideRibbonLabel: 'Black Friday',
      listingMode: `BLACK_FRIDAY_${currentYear}`,
      dealHighlightColor: '#000000',
      chipSelectedColor: '#FFFFFF',
      Icon: SellIcon,
      iconName: 'SellIcon',
      showSitePrefix: !isBeforeBlackFriday(),
      shouldOverrideCollageDailyImage: !isBeforeBlackFriday(),
      prefix: !isBlackFridayDealsWeek() ? 'Early Black Friday' : 'Black Friday',
      showNavLinkOption: isBlackFriday() || isBlackFridayDealsWeek(),
      navLinkTitle: isBlackFridayDealsWeek()
        ? 'Black Friday Week'
        : 'Black Friday',
      navLinkHref: '/black-friday',
      showRibbonsAtFullOpacity: isBlackFriday()
    };
  }

  if (isCyberMonday()) {
    return {
      label: 'Cyber Monday',
      // This is due to the overlap of Black Friday and Cyber Monday
      value: 'BLACK_FRIDAY',
      showSnowFall: true,
      overrideBannerBgColor: '#CC231E',
      showCountdown: false,
      eventStartDate: config.DATES.CYBER_MONDAY_START,
      saleLink: config.EVENT_LINKS.CYBER_MONDAY,
      isEarlySale: false,
      recheckStagedDeals: true,
      updatePageDealItemsForAllPageDeals: true,
      updateEligiblePageDealOnceEvery30Mins: true,
      overrideEmailLinksNumber: 20,
      shouldShowAPINotice: true,
      affiliateTag: config.AFFILIATE_TAGS.CYBER_MONDAY_WEEK,
      overrideEmailEventLink: `https://www.jungle.deals/cyber-monday`,
      // This is due to the overlap of Black Friday and Cyber Monday
      listingMode: `BLACK_FRIDAY_${currentYear}`,
      dealHighlightColor: '#000000',
      chipSelectedColor: '#FFFFFF',
      Icon: SellIcon,
      iconName: 'SellIcon',
      shouldShowDeals: true,
      showSitePrefix: true,
      shouldOverrideCollageDailyImage: true,
      showNavLinkOption: true,
      navLinkTitle: 'Cyber Monday',
      navLinkHref: '/cyber-monday',
      showRibbonsAtFullOpacity: true
    };
  }

  if (displayTwelveDaysOfDeals()) {
    return {
      label: 'Twelve Days of Deals',
      value: 'TWELVE_DAYS_OF_DEALS',
      showSnowFall: true,
      showCountdown: true,
      overrideBannerBgColor: '#235E6F',
      eventStartDate: config.DATES.TWELVE_DAYS_OF_DEALS_START,
      overrideCountdownMessage: `Twelve Days of Deals: Day ${getDaysBetweenNowAndDate(
        config.DATES.TWELVE_DAYS_OF_DEALS_START
      )} of 12`,
      saleLink: config.EVENT_LINKS.TWELVE_DAYS_OF_DEALS,
      isEarlySale: false,
      listingMode: `TWELVE_DAYS_OF_DEALS_${currentYear}`,
      dealHighlightColor: colors.jdbPurple,
      chipSelectedColor: colors.primeSelected,
      Icon: CelebrationIcon,
      iconName: 'CelebrationIcon',
      shouldShowDeals: true,
      showSitePrefix: true,
      shouldOverrideCollageDailyImage: true
    };
  }

  if (displayShippingCutoffMessage()) {
    return {
      label: 'Shipping Cutoff',
      // This is due to the overlap of Black Friday and Cyber Monday
      value: 'SHIPPING_CUTOFF',
      overrideCountdownMessage: 'Shipping cutoff for last-minute gifts:',
      showSnowFall: true,
      overrideBannerBgColor: '#CC231E',
      showCountdown: true,
      eventStartDate: config.DATES.SHIPPING_CUTOFF_MESSAGE_END,
      saleLink: config.EVENT_LINKS.SHIPPING_CUTOFF,
      isEarlySale: false,
      affiliateTag: config.AFFILIATE_TAGS.CYBER_MONDAY_WEEK,
      // This is due to the overlap of Black Friday and Cyber Monday
      listingMode: `SHIPPING_CUTOFF_${currentYear}`,
      dealHighlightColor: '#000000',
      chipSelectedColor: '#FFFFFF',
      Icon: SellIcon,
      iconName: 'SellIcon',
      shouldShowDeals: false,
      showSitePrefix: false,
      shouldOverrideCollageDailyImage: false
    };
  }

  return null;
};

// used on backend for future product database additions
// if the asin exists in the event deals database table
export const getFutureEventListingMode = (): string => {
  if (isBeforePrimeDay() || isPrimeDay() || config.IS_PRIME_RUN_UP) {
    return `PRIME_DAY_${currentYear}`;
  }

  if (isBeforeLaborDay() || isLaborDay()) {
    return `LABOR_DAY_${currentYear}`;
  }

  if (
    isBeforeBlackFriday() ||
    isBlackFridayDealsWeek() ||
    isBlackFriday() ||
    isCyberMonday()
  ) {
    return `BLACK_FRIDAY_${currentYear}`;
  }

  return 'UNKNOWN';
};

// used for day of database additions for listing more
export const getCurrentEventListingMode = (): string => {
  // if something is added on prime day, just count it as a prime day product
  if (isPrimeDay()) {
    return `PRIME_DAY_${currentYear}`;
  }

  return 'UNKNOWN';
};

// used for staging the listing mode for the event
export const hasFutureOrCurrentEvent = (): boolean => {
  return (
    isBeforeLaborDay() ||
    isBeforePrimeDay() ||
    isPrimeDay() ||
    isLaborDay() ||
    isBlackFriday() ||
    isBlackFridayDealsWeek() ||
    isCyberMonday() ||
    isBeforeBlackFriday() ||
    isBeforeCyberMonday()
  );
};
