const clearLocalStorageParameters = (params: string[]) => {
  if (typeof window !== 'undefined' && window) {
    params.forEach((key) => {
      window.localStorage.removeItem(`dashboard_${key}`);
    });
  }
};

export const setLocalStorageParameters = (params: {
  [key: string]: string | boolean | number | string[];
}) => {
  if (typeof window !== 'undefined' && window) {
    Object.keys(params).forEach((key) => {
      window.localStorage.setItem(
        `dashboard_${key}`,
        JSON.stringify(params[key])
      );
    });
  }
};

export const clearDashboardParams = () => {
  const paramsToClear = [
    'sort',
    'filter_only_favorites',
    'filter_only_coupons',
    'filter_only_subscribe_save',
    'filter_without_posts',
    'filter_with_reviews',
    'filter_category_filters'
  ];

  clearLocalStorageParameters(paramsToClear);
};
