import React, { useEffect } from 'react';
import socketIOClient from 'socket.io-client';
import config from '@configFile';
import { useGetUserData } from '@hooks/useGetUserData';
import { useSnackbar } from '@hooks/useSnackbar';
import queryClient from '../queryClient';

const ENDPOINT = config.CURRENT_URL;

const Sockets: React.FC = () => {
  const { data: user } = useGetUserData();
  const { showMessage } = useSnackbar();

  useEffect(() => {
    // Ensure the user and user.id are available before proceeding
    if (user?.isAdmin) {
      const socket = socketIOClient(ENDPOINT);
      const isAdmin = !!user?.isAdmin;

      socket.on('refresh-approvals', () => {
        // TODO: uuid
        if (isAdmin) {
          queryClient.refetchQueries('site-metrics');
        }
      });

      socket.on('send-client-message', (options) => {
        // TODO: uuid
        if (isAdmin) {
          showMessage(options.message);
        }
      });

      // Clean up when component unmounts or user changes
      return () => {
        socket?.disconnect();
      };
    }

    return () => {};
  }, [user]); // Depend only on user, since isAdmin is derived from user

  return <span />;
};

export default Sockets;
