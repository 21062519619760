/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
// import queryClient from '../queryClient';
import { GiftCardType } from '../types';

const queryGiftCards = async (): Promise<GiftCardType[]> => {
  const result = await axios.get(`/api/gift-cards`);
  return result.data;
};

export function useGiftCards(): UseQueryResult<GiftCardType[]> {
  return useQuery<GiftCardType[], Error>(
    `all-gift-cards`,
    () => queryGiftCards(),
    {
      onError: () => {
        console.error('Something went wrong while fetching the gift cards');
      }
    }
  );
}
