const UPDATE_CONFIG = {
  RECHECK_TIME: '2020-04-20 04:20:00',
  INACCURATE_RECHECK_TIME: '2001-04-20 04:20:00',
  TIERS: [
    {
      name: 'Tier 1',
      shouldBePurchasedAfterDateFirstActive: false,
      /*
       In tiers, these are the minimum days between a post once again being recycled to the top of the site.
       */
      updateDays: 55,
      /*
        If the product expires and then comes back in stock we should consider that by rewinding. Given a rewind
         time we remove this many days from the post being expired.
      */
      expiredRewind: 7,
      /*
        The threshold to be in this tier
      */
      salesThreshold: 750
    },
    {
      name: 'Tier 2',
      shouldBePurchasedAfterDateFirstActive: false,
      updateDays: 65,
      expiredRewind: 9,
      salesThreshold: 350
    },
    {
      name: 'Tier 3',
      shouldBePurchasedAfterDateFirstActive: false,
      updateDays: 108,
      expiredRewind: 15,
      salesThreshold: 200
    },
    {
      name: 'Tier 4',
      shouldBePurchasedAfterDateFirstActive: false,
      updateDays: 102,
      expiredRewind: 15,
      salesThreshold: 100
    },
    {
      name: 'Tier 5',
      shouldBePurchasedAfterDateFirstActive: true,
      updateDays: 128,
      expiredRewind: 15,
      salesThreshold: 50
    },
    // TODO: enable when ready
    {
      name: 'Tier 6',
      shouldBePurchasedAfterDateFirstActive: true,
      // TODO: fix to trigger update
      updateDays: 265,
      expiredRewind: 23,
      salesThreshold: 40
    },
    {
      name: 'Tier 7',
      shouldBePurchasedAfterDateFirstActive: true,
      // TODO: fix to trigger update
      updateDays: 365,
      expiredRewind: 23,
      salesThreshold: 0
    }
  ],
  MINIMUM_DAYS_BETWEEN_TOY_LIVE_DATE: 12,
  // TODO: put this in corrections
  EXTRA_DEALS_BANNED: [
    'save 5% on 2 select items',
    'get 6 for the price of 5',
    'save 15% on 2 select items',
    'promotion available',
    'save 10% on 2 select items',
    'save 20% when you buy $50 of select items',
    'buy 4, save 5%',
    'on 3 select item(s)',
    '5% off purchase of 2 items',
    'save 25% on 1 when you buy 2',
    'buy 2, save 25% on 1',
    'save 15% when you buy $20 of select items',
    'you made a prime order and claimed $5',
    'on 2 select item(s)',
    'buy 5, save 5%',
    '5% off purchase of 3 items',
    'save 8% when you buy $300 of select items',
    'get 9 for the price of 8',
    'get 9 for the price of 8. offered by amazon.com. see more products in this promotion |',
    'get 9 for the price of 8. offered by amazon.com. see more products in this promotion | ',
    'save 5% on 3 select items',
    'save 20% on 3 select items',
    'save 5% on 5 select items',
    'save $2 on 2 select items',
    'save 5% on 5 select items',
    'save 5% on 2 select items'
  ]
};

export const getTierFromTotalSold = (totalSold: number) => {
  const { TIERS } = UPDATE_CONFIG;

  for (let i = 0; i < TIERS.length; i++) {
    if (totalSold >= TIERS[i].salesThreshold) {
      return TIERS[i];
    }
  }

  // return last tier
  return TIERS[TIERS.length - 1];
};

export default UPDATE_CONFIG;
