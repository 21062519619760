import React from 'react';
import { Typography } from '@mui/material';

import { PageContainer } from '../../../../components';

const AddedToEmailListsMessage = ({
  showPageContainer
}: {
  showPageContainer: boolean;
}) => {
  return (
    <PageContainer
      title="Added to email lists"
      fullWidth
      showContainer={showPageContainer}
    >
      <Typography variant="body1">
        You&apos;re all set up! You should be receiving your first email soon!
      </Typography>
    </PageContainer>
  );
};

export default AddedToEmailListsMessage;
