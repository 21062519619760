import axios from 'axios';
import { TrackedUse } from '../types';

const trackUse = async (click: TrackedUse) => {
  await axios.post('/api/heartbeat', {
    ...click
  });
};

export default trackUse;
