import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  FormGroup,
  Typography,
  TextField,
  Checkbox,
  Button,
  ListItemIcon,
  ListItem,
  ListItemText,
  Stack,
  List,
  Grid,
  Link,
  FormControlLabel
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '@configFile';
import { useGetUserData } from '@hooks/useGetUserData';
import { useEmailSignUp } from '@hooks/useEmail';
import { useVerifyRecapture, RecaptchaResponse } from '@hooks/useRecaptcha';
import UpcomingEvents from '@components/UpcomingEvents';
import EmailListUI from '@components/EmailLists/components/EmailListUI';
import Loading from '@components/Loading';
import PageContainer from '@components/PageContainer';
import { useSignup } from '@pages/Signup/useSignup';
import {
  isValidEmail,
  getParamsFromURLSearch,
  isNameProhibited
} from '@utils/index';
import mailingLists from '@utils/mailingLists';

import FeedSidebar from '@pages/Blog/components/FeedSidebar';
import AddedToEmailListsMessage from './components/AddedToEmailListsMessage';
import LoadingModal from './components/LoadingModal';

// Todo: Fix password requirements
interface EmailProps {
  showPageContainer?: boolean;
  showReasons?: boolean;
}

const Email = ({
  showPageContainer = true,
  showReasons = true
}: EmailProps) => {
  const location = useLocation();
  const { search } = location;

  const defaultEmail = getParamsFromURLSearch(search)?.email || '';
  const defaultFirstName = getParamsFromURLSearch(search)?.firstName || '';
  const defaultLastName = getParamsFromURLSearch(search)?.lastName || '';

  const [email, setEmail] = useState(defaultEmail);
  const [firstName, setFirstName] = useState(defaultFirstName);
  const [lastName, setLastName] = useState(defaultLastName);
  const [hasVerified, setHasVerified] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [hasAccount, setHasAccount] = useState<boolean>(false);
  const [canGetAccount, setCanGetAccount] = useState<boolean>(true);
  const [hasTouchedPasswordField, setHasTouchedPasswordField] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [termsChecked, setTermsChecked] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<string[]>(
    mailingLists
      .filter((ml) => ml.defaultCheckedForSignUp)
      .map((emailList) => emailList.id)
  );
  const recaptcha = React.useRef();
  const { mutate: verifyRecaptcha } = useVerifyRecapture();
  const { mutate: signUpForMailingLists, isLoading: isSigningUp } =
    useEmailSignUp();
  const { mutate: signup } = useSignup();
  const { data: user, isLoading: isLoadingUser } = useGetUserData();

  const onChange = (value: string) => {
    verifyRecaptcha(value, {
      onSuccess: (data: RecaptchaResponse) => {
        if (data.success) {
          setHasVerified(true);
        } else {
          // eslint-disable-next-line no-alert
          alert('Please complete the captcha to verify you are not a robot');
        }
      },
      onError: () => {
        // eslint-disable-next-line no-alert
        alert('Please try again later');
      }
    });
  };

  const getAccount = () => {
    const account = {
      firstName: firstName.trim(),
      email: email.trim(),
      lastName: lastName.trim(),
      password: password.trim()
    };

    // get the account if it exists
    signup(account, {
      onError: () => {
        // eslint-disable-next-line no-alert
        alert('There was an error signing up, please try again later');
      },
      onSuccess: () => {
        setHasAccount(true);
      }
    });
  };

  const submitSignup = () => {
    signUpForMailingLists(
      {
        email,
        selectedEmails
      },
      {
        onSuccess: (data) => {
          setHasSubmitted(true);
          setHasAccount(!!data.hasAccount);
          setCanGetAccount(!!data.canGetAccount);
        },
        onError: () => {
          // eslint-disable-next-line no-alert
          alert('There was an error signing up, please try again later');
        }
      }
    );
  };

  const handleClickCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    mailingListId: string
  ) => {
    e.preventDefault();
    const updatedEmails = e.target.checked
      ? [...selectedEmails, mailingListId]
      : selectedEmails.filter((id) => id !== mailingListId);
    setSelectedEmails(updatedEmails);
  };

  const handleClickListRow = (
    e: React.MouseEvent<HTMLLIElement>,
    mailingListId: string
  ) => {
    e.preventDefault();

    const isNotCurrentlyChecked = !selectedEmails.includes(mailingListId);

    const updatedEmails = isNotCurrentlyChecked
      ? [...selectedEmails, mailingListId]
      : selectedEmails.filter((id) => id !== mailingListId);
    setSelectedEmails(updatedEmails);
  };

  const renderMainStuff = () => {
    return (
      <Box>
        {isSigningUp && <LoadingModal />}
        {isLoadingUser && <Loading />}
        {!isLoadingUser && !user ? (
          <Box sx={{ width: '100%' }}>
            {showReasons ? (
              <Stack
                spacing={2}
                sx={{
                  maxWidth: '100%',
                  margin: '12px auto 24px auto'
                }}
              >
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary="Get notified FIRST of Amazon's BEST deals!" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary="Find out about deals on actually useful household items, diapers, kids toys etc." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary="Thousands of subscribers and a low unsubscribe rate can't be wrong!" />
                  </ListItem>
                </List>
              </Stack>
            ) : null}
            <Box>
              <FormGroup>
                <List>
                  {mailingLists.map((ml) => {
                    return (
                      <ListItem
                        key={ml.id}
                        onClick={(e) => {
                          handleClickListRow(e, ml.id);
                        }}
                        sx={{
                          cursor: 'pointer'
                        }}
                      >
                        <ListItemText
                          primary={`${ml.displayName}`}
                          secondary={`${ml.displayDescription}`}
                        />
                        <Checkbox
                          edge="end"
                          checked={selectedEmails.includes(ml.id)}
                          onChange={(e) => {
                            handleClickCheckbox(e, ml.id);
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
                <Box
                  mt={2}
                  sx={{
                    width: {
                      xs: '100%'
                    }
                  }}
                >
                  <Grid
                    container
                    rowSpacing={{ xs: 2, md: 1 }}
                    columnSpacing={{ xs: 2, md: 1 }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        label="Email"
                        size="small"
                        name="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                          margin: '0'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </FormGroup>

              <br />
              <ReCAPTCHA
                ref={recaptcha}
                sitekey={config.RECAPTCHA_SITE_KEY}
                onChange={onChange}
              />
              <br />
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitSignup}
                  disabled={
                    !isValidEmail(email) ||
                    !hasVerified ||
                    selectedEmails.length === 0
                  }
                >
                  Let&apos;s do this
                </Button>
              </Box>
            </Box>
          </Box>
        ) : null}
        {!isLoadingUser && user ? <EmailListUI /> : null}
      </Box>
    );
  };

  if (hasSubmitted && !hasAccount && canGetAccount) {
    // let people know that they can give us a password and we can easily make them an account
    return (
      <PageContainer
        title="Set up a new account"
        fullWidth
        showContainer={showPageContainer}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="body1" gutterBottom>
            You&apos;re all set up! You should be receiving your first email
            soon!
          </Typography>
          <div>
            <Typography variant="body1">
              It looks like you don&apos;t have an account with us yet,
              it&apos;s free and unlocks premium features. If you would like to
              make an account, please enter your details below.
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: '12px'
              }}
            >
              <Grid item xs={12}>
                <TextField
                  label="First Name"
                  size="small"
                  name="firstName"
                  autoComplete="given-name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  sx={{
                    margin: '0',
                    maxWidth: '350px'
                  }}
                  error={isNameProhibited(firstName.trim(), lastName.trim())}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Last Name"
                  size="small"
                  variant="outlined"
                  name="lastName"
                  autoComplete="family-name"
                  fullWidth
                  margin="normal"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  sx={{
                    margin: '0',
                    maxWidth: '350px'
                  }}
                  error={isNameProhibited(firstName.trim(), lastName.trim())}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  size="small"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="password"
                  onBlur={() => setHasTouchedPasswordField(true)}
                  error={password.length < 12 && hasTouchedPasswordField}
                  style={{
                    margin: '8px 0',
                    maxWidth: '350px'
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
            </Grid>
            <FormControlLabel
              sx={{ mb: '12px' }}
              control={
                <Checkbox
                  checked={termsChecked}
                  onChange={() => {
                    setTermsChecked(!termsChecked);
                  }}
                  name="acceptTerms"
                  color="primary"
                />
              }
              label={
                <span>
                  I have read and I accept the{' '}
                  <Link href="/privacy-policy" target="_blank" rel="noopener">
                    Privacy Policy
                  </Link>{' '}
                  and{' '}
                  <Link href="/terms" target="_blank" rel="noopener">
                    Terms of Service
                  </Link>
                  .
                </span>
              }
            />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={getAccount}
              disabled={
                password.length < 12 ||
                !termsChecked ||
                isNameProhibited(firstName.trim(), lastName.trim()) ||
                firstName.trim().length < 2 ||
                lastName.trim().length < 2
              }
            >
              Sign up
            </Button>
          </div>
        </Box>
      </PageContainer>
    );
  }

  if (hasSubmitted) {
    return <AddedToEmailListsMessage showPageContainer={showPageContainer} />;
  }

  if (!showPageContainer) {
    return renderMainStuff();
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <PageContainer title="Sign up for our email lists" fullWidth>
          <Box
            sx={{
              maxWidth: '900px',
              textAlign: 'center',
              margin: '0 auto'
            }}
          >
            {renderMainStuff()}
          </Box>
        </PageContainer>
      </Grid>
      <Grid
        item
        sx={{
          display: { xs: 'none', md: 'block' },
          paddingBottom: `1rem`
        }}
        xs={12}
        md={4}
      >
        <UpcomingEvents />
        <FeedSidebar />
      </Grid>
    </Grid>
  );
};

export default Email;
