import React from 'react';
import { Box } from '@mui/material';
import { useGetCurrentSiteEvents } from '@hooks/useSiteEvents';
import EventsList from './Events';

const UpcomingEvents = ({ isMobile = false }: { isMobile?: boolean }) => {
  const { data: currentSiteEvents, isLoading } = useGetCurrentSiteEvents();
  const siteEvents = currentSiteEvents || [];

  if (siteEvents.length === 0) {
    return null;
  }

  return (
    <Box
      sx={
        isMobile
          ? {
              display: {
                xs: 'block',
                lg: 'none'
              },
              marginLeft: '-17px',
              marginRight: '-16px',
              backgroundColor: '#80808029'
            }
          : {}
      }
    >
      <EventsList
        isMobile={isMobile}
        isLoading={isLoading}
        events={siteEvents}
      />
    </Box>
  );
};

export default UpcomingEvents;
