import React from 'react';

import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import ListIcon from '@mui/icons-material/List';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';

export default [
  {
    name: 'Recommendations',
    link: '/mailing-list-recommendations',
    icon: <DynamicFeedIcon />
  },
  {
    name: 'Variations Searched',
    link: '/variations-searched',
    icon: <ScreenSearchDesktopIcon />
  },
  {
    name: 'Deal Lists Admin',
    link: '/deal-lists',
    icon: <ListIcon />
  },
  {
    name: 'Stats',
    link: '/stats',
    icon: <WaterfallChartIcon />
  }
];
